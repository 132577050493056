import { useMutation } from "@apollo/client";
import {
  faCheck,
  faInfoCircle,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextareaAutosize } from "@mui/base";
import { Tooltip } from "@mui/material";
import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import CopyIndividualField from "./../CopyIndividualField.js";
import CustomizationFeedback from "./components/CustomizationFeedback";
import { noteSections } from "./constants.js";
import { defaultList } from "../../../../../assets/data/Dictionary.js";
import ACCEPT_LLM_CUSTOMIZATIONS from "../../../../../graphql/mutations/AcceptLlmCustomization.js";
import GENERATE_LLM_CUSTOMIZATION from "../../../../../graphql/mutations/GenerateLLMCustomization.js";
import { useAuth } from "../../../../../hooks/use-auth.js";
import { useAutosaveContext } from "../../../../../hooks/use-autosave.js";
import { useStreamResult } from "../../../../../hooks/use-stream-result.js";
import Twemoji from "../../../../../Twemoji";
import { alert } from "../../../../common/Alert.js";
import PredictiveInput from "../../../../common/inputs/PredictiveInput.js";
import CascadingDropdown from "../../../../common/menus/CascadingDropdown";
import { ConfirmModal } from "../../../../common/modals/PopupModals.js";
import {
  generateParams,
  getOptions,
  languageCodeToLanguageName,
} from "../utils";

export function SOAPInput({
  fieldName,
  control,
  title,
  headerStyle,
  boxStyle,
  id,
  placeholder = null,
  noteUuid,
  onMouseUp = null,
  isAutoSoap = false,
  aiAssistantEnabled = false,
  languageAiAssistantEnabled = false,
  subheadingsAiAssistantEnabled = false,
}) {
  const { field } = useController({
    name: fieldName,
    control,
  });
  const [generateLlmCustomizations] = useMutation(
    GENERATE_LLM_CUSTOMIZATION,
  );
  const [acceptLlmCustomizations] = useMutation(
    ACCEPT_LLM_CUSTOMIZATIONS,
  );

  //state
  const [beforeContent, setBeforeContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [llmCustomizationUuid, setLlmCustomizationUuid] =
    useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [
    subjectiveTranslationLanguage,
    setSubjectiveTranslationLanguage,
  ] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState("");
  const [isShowingExtraContent, setIsShowingExtraContent] =
    useState(false); //this includes the accept/reject buttons and the thumbs up/down feedback buttons

  //hooks
  const { userUuid } = useAuth();
  const { getValues, setValue } = useFormContext();
  const { connectSSE, isStreaming } = useStreamResult();

  const { cancelDebounce } = useAutosaveContext();

  //constants
  const loadingBeforeStreaming = isLoading && !isStreaming;
  const loadingBeforeAndDuringStreaming = isLoading || isStreaming;

  const inputClassName = ` border ${
    isShowingExtraContent
      ? "border-0 bg-transparent"
      : `rounded-xl mb-4 ${boxStyle}`
  } p-4 mt-2 w-full text-xs md:text-sm h-24 align-top text-gray-700 dark:text-gray-300 resize-none`;
  const extraInputClassName = `border border-t-0 ${boxStyle} p-1 mb-8 rounded-b-md w-full text-xs md:text-sm align-top text-gray-700 dark:text-gray-300 resize-none`;

  const copyText = () => {
    const textToCopy = getValues();
    navigator.clipboard.writeText(textToCopy[fieldName]).then(() => {
      alert("info", "Copied!");
    });
  };

  //handler functions
  const onCustomizationOptionClick = async (
    parentValue,
    childValue,
    fieldNameArg = fieldName,
    translateAll = false,
  ) => {
    const params = generateParams(
      parentValue,
      childValue,
      fieldNameArg,
    );

    const currentContent = getValues(fieldNameArg);
    const currentCustomizationLlmUuid = uuidv4();

    if (!currentContent) {
      alert(
        "error",
        "There is no content to customize in this field!",
      );
      return;
    }

    cancelDebounce(); //avoid race conditions with autosaving

    try {
      setBeforeContent(currentContent);
      setValue(fieldNameArg, "");
      if (fieldNameArg === fieldName) {
        setIsLoading(true);
      }

      const streamResponse = await startStream(
        parentValue,
        fieldNameArg,
        currentCustomizationLlmUuid,
        translateAll,
      );

      if (streamResponse.taskUuid === "" || streamResponse.error) {
        alert(
          "error",
          "An error has occurred. Please try again later.",
        );

        setValue(fieldNameArg, currentContent);
        setIsLoading(false);
      }

      const taskUuid = streamResponse.taskUuid;

      const createLlmCustomizationInput = {
        noteUuid,
        userUuid,
        noteSection: fieldNameArg,
        beforeContent: currentContent,
        actionType: parentValue,
        params: JSON.stringify(params),
        streamingTaskUuid: taskUuid,
        llmCustomizationUuid: currentCustomizationLlmUuid,
      };

      await generateLlmCustomizations({
        variables: {
          createLlmCustomizationInput,
        },
      });
      setLlmCustomizationUuid(currentCustomizationLlmUuid);

      const customizedNoteParams = {
        customizationOption: parentValue,
        customizationSuboption: childValue,
        noteUuid,
        customizationUuid: currentCustomizationLlmUuid,
        noteSection: fieldNameArg,
        params,
      };

      mixpanel.track("Customized Note", customizedNoteParams);
    } catch (err) {
      const failedCustomizationParams = {
        customizationOption: parentValue,
        customizationSuboption: childValue,
        noteUuid,
        fieldNameArg,
        err,
      };

      mixpanel.track(
        "Customization Error",
        failedCustomizationParams,
      );

      setIsLoading(false);
    }
  };

  const startStream = async (
    parentValue,
    fieldNameArg,
    currentCustomizationLlmUuid,
    translateAll = false,
  ) => {
    if (parentValue === "show_abnormals") {
      setValue(fieldNameArg, `Loading...`);
    }

    let response = await connectSSE(
      (message) => {
        const content = getValues(fieldNameArg).replace(
          /Loading...$/,
          "",
        );
        const newContent =
          parentValue === "show_abnormals"
            ? content + message + "Loading..."
            : content + message;
        setValue(fieldNameArg, newContent);
      },
      () => {
        const finalContent = getValues(fieldNameArg)
          .replace(/Loading...$/, "")
          .trim();
        setValue(fieldNameArg, finalContent);
        setIsLoading(false);
        if (fieldNameArg === fieldName) {
          setIsShowingExtraContent(true);
        }
        if (translateAll) {
          sendAcceptedStatusToServer(
            true,
            currentCustomizationLlmUuid,
            true,
            fieldNameArg,
          );
        }
      },
      "AI Writing Assistant",
    );

    return response;
  };

  const sendAcceptedStatusToServer = async (
    accepted,
    llmCustomizationUuidArg,
    translateAll = false,
    fieldNameArg = fieldName,
  ) => {
    //if the user rejects, we need to reset the content to the original content, but if they accept, we need to pass back the new content
    const finalContent = accepted
      ? getValues(fieldNameArg)
      : beforeContent;

    const finalLLMCustomizationUuid =
      llmCustomizationUuidArg || llmCustomizationUuid;
    const AcceptLLMCustomizationsInput = {
      llmCustomizationUuid: finalLLMCustomizationUuid,
      accepted,
      finalContent,
    };
    setIsShowingExtraContent(false);

    //if the user accepts the translation, we need to check if they want to translate the rest of the note
    //but only if they haven't already translated the rest of the note
    if (subjectiveTranslationLanguage && accepted && !translateAll) {
      setIsModalShowing(true);
    }
    const confirmedNoteCustomizationParams = {
      customizationUuid: finalLLMCustomizationUuid,
      noteUuid,
      accepted,
      noteSection: fieldNameArg,
    };
    mixpanel.track(
      "Confirmed Note Customization",
      confirmedNoteCustomizationParams,
    );

    try {
      await acceptLlmCustomizations({
        variables: {
          AcceptLLMCustomizationsInput,
        },
      });
    } catch (err) {
      const acceptingCustomizationErrorParams = {
        finalLLMCustomizationUuid,
        noteUuid,
        accepted,
        err,
      };
      mixpanel.track(
        "Accepting Customization Error",
        acceptingCustomizationErrorParams,
      );
    }
  };

  const acceptChanges = () => {
    setBeforeContent(null);
    sendAcceptedStatusToServer(true);
  };

  const rejectChanges = () => {
    if (beforeContent !== null) {
      setValue(fieldName, beforeContent);
    }
    setBeforeContent(null);
    sendAcceptedStatusToServer(false);
  };

  const injectCallbacks = (options) => {
    return options?.map((option) => ({
      ...option,
      items: option.items.map((item) => ({
        ...item,
        callback: () => {
          if (
            fieldName === "subjective" &&
            option.value === "translate_to"
          ) {
            setSubjectiveTranslationLanguage(item.value);
          } else {
            setSubjectiveTranslationLanguage("");
          }
          onCustomizationOptionClick(option.value, item.value);
        },
      })),
    }));
  };

  const handleTranslateAll = () => {
    setIsModalShowing(false);
    noteSections.forEach((mappedFieldName) => {
      if (
        !getValues(mappedFieldName) ||
        mappedFieldName === fieldName
      ) {
        return;
      }
      onCustomizationOptionClick(
        "translate_to",
        subjectiveTranslationLanguage,
        mappedFieldName,
        true,
      );
    });
  };

  //getting the options for the cascading dropdown
  const options = getOptions(
    fieldName,
    languageAiAssistantEnabled,
    subheadingsAiAssistantEnabled,
  );
  const updatedOptions = injectCallbacks(options);

  return (
    <>
      <ConfirmModal
        shouldShow={isModalShowing}
        setShouldShow={setIsModalShowing}
        title="Translation Detected"
        hideModal={() => setIsModalShowing(false)}
        cancelAction={() => setIsModalShowing(false)}
        confirmAction={handleTranslateAll}
        confirmText="Translate All"
        cancelText="Only Translate Subjective"
      >
        <div>
          <h5 className="text-sm">
            We noticed that you translated the Subjective section of
            the note to{" "}
            {languageCodeToLanguageName(
              subjectiveTranslationLanguage,
            )}
            . Would you like the rest of your note to be translated to{" "}
            {languageCodeToLanguageName(
              subjectiveTranslationLanguage,
            )}
            ?
          </h5>
        </div>
      </ConfirmModal>
      <div
        id={id}
        className="relative"
        data-testid={`${title}-input`}
      >
        <div className="absolute z-10 -top-3 left-0 flex flex-row items-center justify-items-center">
          {title && (
            <div
              className={`rounded-tr-full rounded-tl-full rounded-br-full py-1 px-4 ${headerStyle}`}
              data-testid="input-title"
            >
              <div className="flex">
                <h4 className="text-white dark:text-gray-100 whitespace-nowrap">
                  {title}
                </h4>
              </div>
            </div>
          )}
          {!aiAssistantEnabled ||
          !isAutoSoap ? null : isShowingExtraContent ? (
            <div
              className={`border ${boxStyle} flex items-center rounded-xl ml-1 divide-x`}
            >
              <button
                className="hover:bg-green-600 bg-green-500 text-white text-xs font-medium px-2 py-1 rounded-l-xl transition-all"
                onClick={acceptChanges}
                data-testid="accept-button"
              >
                Accept <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                className="hover:bg-red-600 bg-red-500 text-white text-xs font-medium px-2 py-1 rounded-r-xl transition-all"
                onClick={rejectChanges}
                data-testid="reject-button"
              >
                Reject <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ) : (
            <CascadingDropdown
              options={updatedOptions}
              setIsMenuOpen={setIsMenuOpen}
              isTriggerComponentDisabled={
                loadingBeforeAndDuringStreaming
              }
              triggerComponent={({ onClick }) => (
                <div
                  className={`h-8 w-8 flex items-center justify-items-center p-1.5 rounded-full ${
                    loadingBeforeAndDuringStreaming
                      ? "bg-gray-400 cursor-not-allowed"
                      : "cursor-pointer shadow-md"
                  } ${
                    !loadingBeforeAndDuringStreaming && isMenuOpen
                      ? "bg-indigo-600 border-yellow-200"
                      : !loadingBeforeAndDuringStreaming
                      ? "bg-indigo-500 border-indigo-500"
                      : "border-gray-400"
                  } border-2 ${
                    !loadingBeforeAndDuringStreaming &&
                    "hover:bg-indigo-600 hover:border-yellow-200 transition-all"
                  }`}
                  onClick={onClick}
                  title="Trigger Writing Assistant"
                  data-testid="cascading-dropdown-trigger"
                >
                  <Twemoji className="text-xl z-20" emoji="✨" />
                </div>
              )}
              menuClassName="transform translate-x-[42px] -translate-y-[1px]"
            />
          )}
        </div>

        {loadingBeforeStreaming ||
          (!getValues(fieldName) && isStreaming && (
            <div className="absolute inset-0 flex justify-center items-center z-30">
              <FontAwesomeIcon
                data-testid="loading-spinner"
                icon={faSpinner}
                className="animate-spin text-gray-500 dark:text-gray-400 text-xl"
              />
            </div>
          ))}
        {isShowingExtraContent && beforeContent !== null ? (
          <div
            className={`flex flex-row space-x-4 h-full ${boxStyle} border rounded-t-xl`}
          >
            <TextareaAutosize
              className={inputClassName}
              value={getValues(fieldName)}
              onChange={(e) => setValue(fieldName, e.target.value)}
              data-testid="after-content-textarea"
              placeholder="After content"
              readOnly
            />
            <div className="w-full border-l px-4 pb-4">
              <h4 className="my-2">
                Before{" "}
                <Tooltip
                  arrow
                  placement="right"
                  title="Click 'Reject' to keep this version."
                >
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </Tooltip>
              </h4>
              <TextareaAutosize
                className={
                  "italic w-full text-xs md:text-sm bg-transparent align-top text-gray-700 dark:text-gray-300 resize-none"
                }
                value={beforeContent}
                readOnly
                data-testid="before-content-textarea"
                placeholder="Before content"
              />
            </div>
          </div>
        ) : (
          <>
            <CopyIndividualField
              onClick={() => copyText()}
              dataTestId="copy-button"
            />
            <PredictiveInput
              isStreaming={isStreaming}
              field={field}
              placeholder={
                !placeholder
                  ? `Type ${title} notes here...`
                  : placeholder
              }
              positionStyles="p-4 h-24 my-2 border border-transparent"
              dictionary={defaultList}
              inputClassName={inputClassName}
              onMouseUp={onMouseUp}
              disabled={isShowingExtraContent}
            />
          </>
        )}

        {isShowingExtraContent && (
          <div
            className={extraInputClassName}
            data-testid="extra-content"
          >
            <CustomizationFeedback
              llmCustomizationUuid={llmCustomizationUuid}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SOAPInput;
