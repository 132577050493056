import axios from "axios";
import mixpanel from "mixpanel-browser";

import { getTempScribenoteFlask } from "../utils";
import { bytesToMB } from "./utils";

export async function generateS3() {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await postS3(`${SCRIBENOTE_FLASK}/generate_s3/`);
}

export async function signS3Attachment(fileType) {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await postS3(
    `${SCRIBENOTE_FLASK}/sign_s3_attachment/?fileType=${fileType}`,
  );
}

async function postS3(endpoint) {
  const csrf_token = localStorage.getItem("accessCsrf");

  return await axios.get(endpoint, {
    headers: {
      "Access-Control-Allow-Origin": true,
      "X-CSRF-Token": csrf_token,
    },
    withCredentials: true,
  });
}

export default async function signS3() {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await postS3(`${SCRIBENOTE_FLASK}/sign_s3/`);
}

function createS3Chunks(file) {
  let chunkSize = 5 * 1024 * 1024; // 5 MB is the min size allowed by s3
  let start = 0;
  let end = file.size;
  let chunks = [];
  while (start < end) {
    chunks.push(file.slice(start, start + chunkSize));
    start += chunkSize;
  }

  // map as 1 indexed dict because thats what s3 wants
  return chunks.reduce((res, blob, idx) => {
    res[idx + 1] = blob;
    return res;
  }, {});
}

async function uploadS3MultipartChunks(
  signedUrls,
  chunks,
  setUploadStatus,
) {
  return await Promise.all(
    Object.entries(signedUrls).map(([partNo, signedUrl]) =>
      uploadS3MultipartChunk(
        partNo,
        signedUrl,
        chunks[partNo],
        setUploadStatus,
      ),
    ),
  );
}

async function uploadS3MultipartChunk(
  partNo,
  signedURL,
  chunk,
  setUploadStatus,
) {
  const res = await fetch(signedURL, { method: "PUT", body: chunk });
  setUploadStatus((prev) => ({
    ...prev,
    numChunksCompleted: prev.numChunksCompleted + 1,
  }));
  return {
    ETag: res.headers.get("etag"),
    PartNumber: parseInt(partNo),
  };
}

async function completeS3Multipart(fileName, uploadId, parts) {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await axios.post(
    `${SCRIBENOTE_FLASK}/complete_s3_multipart/`,
    { fileName, parts, uploadId },
    {
      headers: {
        "Access-Control-Allow-Origin": true,
        "X-CSRF-Token": localStorage.getItem("accessCsrf"),
      },
      withCredentials: true,
    },
  );
}

async function abortS3Multipart(fileName, uploadId) {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await axios.post(
    `${SCRIBENOTE_FLASK}/abort_s3_multipart/`,
    { fileName, uploadId },
    {
      headers: {
        "Access-Control-Allow-Origin": true,
        "X-CSRF-Token": localStorage.getItem("accessCsrf"),
      },
      withCredentials: true,
    },
  );
}

async function signS3Multipart(numParts) {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await axios.post(
    `${SCRIBENOTE_FLASK}/sign_s3_multipart/`,
    { numParts },
    {
      headers: {
        "Access-Control-Allow-Origin": true,
        "X-CSRF-Token": localStorage.getItem("accessCsrf"),
      },
      withCredentials: true,
    },
  );
}

export async function s3SingleUpload(
  blob,
  setUploadStatus,
  syncedRecordingUUID,
) {
  const formData = new FormData();
  const signS3Response = await signS3();
  const { data, storageObjectName } = signS3Response.data;
  const audioStorageLink = data.url;
  const { fields } = data;

  for (const key in fields) {
    formData.append(key, fields[key]);
  }
  formData.append("file", blob);

  const cancelTokenSource = axios.CancelToken.source();
  let uploadStartTime = Date.now();

  const uploadTimeout = setTimeout(async () => {
    const elapsedTime = (Date.now() - uploadStartTime) / 1000;
    cancelTokenSource.cancel("Request timed out after 10 seconds");
    mixpanel.track("Upload Progress Timeout", {
      error: "Request timed out after 10 seconds",
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      elapsedTime,
      audioStorageLink,
      syncedRecordingUUID,
    });
  }, 10000);

  const syncUploadTimeout = setTimeout(async () => {
    const elapsedTime = (Date.now() - uploadStartTime) / 1000;
    cancelTokenSource.cancel("Request timed out after 120 seconds");
    mixpanel.track("Sync Upload Timeout", {
      error: "Request timed out after 120 seconds",
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      elapsedTime,
      audioStorageLink,
      syncedRecordingUUID,
    });
  }, 120000);
  try {
    await axios.post(data.url, formData, {
      headers: { "Access-Control-Allow-Origin": true },
      transformRequest: (formData) => formData,
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (progressEvent) => {
        clearTimeout(uploadTimeout);
        setUploadStatus({
          numChunksCompleted: progressEvent.loaded,
          numChunksTotal: progressEvent.total,
        });
      },
    });

    return { storageObjectName, audioStorageLink };
  } catch (error) {
    if (axios.isCancel(error)) {
      mixpanel.track("Upload Canceled", {
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        error: error.message || error,
        storageObjectName,
        audioStorageLink,
        syncedRecordingUUID,
      });
    } else {
      mixpanel.track("Upload Failed", {
        fileSizeMB: bytesToMB(blob.size).toFixed(2),
        error: error.message || error,
        storageObjectName,
        audioStorageLink,
        syncedRecordingUUID,
      });
    }
    throw error; // Re-throw for parent function to catch
  } finally {
    clearTimeout(uploadTimeout);
    clearTimeout(syncUploadTimeout);
  }
}

export async function s3MultipartUpload(
  blob,
  setUploadStatus,
  syncedRecordingUUID,
) {
  var fileName, uploadId, signedUrls;
  try {
    const chunks = createS3Chunks(blob);
    const totalChunks = Object.keys(chunks).length;
    setUploadStatus({
      numChunksTotal: totalChunks,
      numChunksCompleted: 0,
    });
    const { data } = await signS3Multipart(totalChunks);
    fileName = data.file_name;
    uploadId = data.upload_id;
    signedUrls = data.signed_urls;

    const parts = await uploadS3MultipartChunks(
      signedUrls,
      chunks,
      setUploadStatus,
    );

    const {
      data: { audioStorageLink, storageObjectName },
    } = await completeS3Multipart(fileName, uploadId, parts);
    return { audioStorageLink, storageObjectName };
  } catch (error) {
    mixpanel.track("Error in S3 multipart upload", {
      error: error.message || error,
      fileSizeMB: bytesToMB(blob.size).toFixed(2),
      fileName,
      uploadId,
      syncedRecordingUUID,
    });
    if (fileName && uploadId) {
      abortS3Multipart(fileName, uploadId);
    }
    throw error;
  }
}
