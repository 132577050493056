import mixpanel from "mixpanel-browser";

import { s3MultipartUpload, s3SingleUpload } from "./SignS3";
import { bytesToMB } from "./utils";

export async function uploadToCloud({
  blob,
  createNewNote,
  jobType,
  setUploadStatus = () => {},
  syncedRecordingUUID = undefined,
  uploadType = "chunked",
}) {
  const fileSizeMB = bytesToMB(blob.size).toFixed(2);
  mixpanel.track("Chunked Upload Started", {
    fileSizeMB,
    syncedRecordingUUID,
  });
  const uploadStartTime = Date.now();

  const uploadFunc =
    uploadType == "chunked" ? s3MultipartUpload : s3SingleUpload;
  try {
    mixpanel.track("Upload Started", {
      fileSizeMB,
      syncedRecordingUUID,
      uploadType,
    });
    var { storageObjectName, audioStorageLink } = await uploadFunc(
      blob,
      setUploadStatus,
      syncedRecordingUUID,
    );
  } catch (error) {
    mixpanel.track("S3 upload failed", {
      fileSizeMB,
      error: error.message || error,
      storageObjectName,
      audioStorageLink,
      uploadType,
      syncedRecordingUUID,
    });
    throw error; // Re-throw for parent function to catch
  } finally {
    setTimeout(() => {
      setUploadStatus({
        numChunksTotal: 0,
        numChunksCompleted: 0,
      });
    }, 2000);
  }
  const elapsedTime = (Date.now() - uploadStartTime) / 1000;
  console.log(elapsedTime, uploadType);
  mixpanel.track("Upload Completed", {
    elapsedTime,
    syncedRecordingUUID,
    uploadType,
  });
  try {
    const newNoteData = await createNewNote(
      audioStorageLink,
      storageObjectName,
      jobType,
    );
    return { success: true, note: newNoteData };
  } catch (error) {
    mixpanel.track("Note Creation Failed", {
      fileSizeMB,
      error: error.message || error,
      storageObjectName,
      audioStorageLink,
      syncedRecordingUUID,
    });
    throw error; // Re-throw for parent function to catch
  }
}

export async function submitPreRecordedSampleAudio({
  createNewNote,
  uploadType = "chunked",
}) {
  /**
   * Used for submitting onboarding audio file if the user chooses to use pre-recorded.
   *
   * Why are we doing this?
   * Cause the user needs a NEW note so that they can edit it and see it in their dashboard after the process.
   * However, we need to make that new note with an audio file. So saving the file in assets, then getting the
   *   blob, then uploading the file with the same function as regular recordings used seems to keep things clean
   * while allowing a new note to be created every time.
   *
   */

  const audioUrl = require("../assets/other/onboarding-appt-example.wav");

  try {
    const response = await fetch(audioUrl);
    const blob = await response.blob();
    const newNoteData = await uploadToCloud({
      blob,
      createNewNote,
      uploadType,
    });
    return newNoteData;
  } catch (error) {
    alert(
      "error",
      "An error occurred. Please try again later or contact Scribenote Support.",
    );
    throw error;
  }
}

export async function submitTestingSampleAudio({
  createNewNote,
  audioUrl,
  uploadType = "chunked",
}) {
  /**
   * Used for submitting testing audio files if the tester chooses to use pre-record
   *
   * Why are we doing this?
   * Cause the user needs a NEW note so that they can edit it and see it in their dashboard after the process.
   * However, we need to make that new note with an audio file. So saving the file in assets, then getting the
   *   blob, then uploading the file with the same function as regular recordings used seems to keep things clean
   * while allowing a new note to be created every time.
   *
   */

  const importedAudioUrl = audioUrl;

  fetch(importedAudioUrl)
    .then((response) => response.blob())
    .then((blob) => {
      uploadToCloud({ blob, createNewNote, uploadType });
    })
    .catch(() => {
      alert(
        "error",
        "An error occurred. Please try again later or contact Scribenote Support.",
      );
    });
}
