import { useMutation } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { alert } from "../components/common/Alert";
import INITIALIZE_STREAM from "../graphql/mutations/InitializeStream";
import { getTempScribenoteFlask } from "../utils";

export function useStreamResult() {
  const [isStreaming, setIsStreaming] = useState(false);
  const [initializeStream] = useMutation(INITIALIZE_STREAM);

  const connectSSE = async (
    updateCallback,
    finalizeCallback,
    functionDescription,
  ) => {
    const SCRIBENOTE_FLASK = getTempScribenoteFlask();

    // Critically - don't just copy / paste this code into
    // a mobile app and expect it to work.
    // With credentials relies on including http-only cookies
    // which the mobile apps currently do not support.

    // Generate a unique task UUID
    const taskUuid = uuidv4();

    //tie the streaming task uuid to the user, so the user can only access their own stream
    let response = await initializeStream({
      variables: {
        taskUuid,
      },
    });

    if (!response?.data?.initializeStream?.ok) {
      mixpanel.track("Stream Initialization Error", {
        streamingTaskUuid: taskUuid,
        functionDescription,
      });
      return { taskUuid: "", error: true };
    }

    //establish a connection to the stream server
    const eventSource = new EventSource(
      `${SCRIBENOTE_FLASK}/stream?channel=llm-streaming-task:${taskUuid}`,
      {
        withCredentials: true,
      },
    );

    // If the connection fails, return an error
    if (!eventSource) {
      mixpanel.track("Stream Event Source Error", {
        streamingTaskUuid: taskUuid,
      });
      return { taskUuid: "", error: true, functionDescription };
    }

    // If the connection fails, return an error
    if (!eventSource) {
      return { taskUuid: "", error: true };
    }

    setIsStreaming(true);

    function reset() {
      eventSource.close();
      setIsStreaming(false);
      if (finalizeCallback) {
        finalizeCallback();
      }
    }

    eventSource.onmessage = function (event) {
      const newMessage = event?.data;

      if (!newMessage) {
        return;
      }

      // new message format is {"data": " blah"} or {"event": "blah"}
      const parsedMessage = JSON.parse(newMessage);
      const type = parsedMessage?.event || "";
      const data = parsedMessage?.data || "";

      if (type === "stream-end") {
        reset(); // Close the connection gracefully
        return;
      } else if (type === "stream-error") {
        alert(
          "error",
          "An error has occurred. Please try again later.",
        );
        mixpanel.track("SSE Error", {
          message: "Received 'stream-error' from server",
          functionDescription,
        });
        reset(); // Close the connection on error
        return;
      } else {
        updateCallback(data);
      }
    };

    eventSource.onerror = function () {
      mixpanel.track("SSE Error", {
        message:
          "Connection errored without message from server. This is likely a connection issue.",
        functionDescription,
      });
      alert(
        "error",
        "An error has occurred. Please check your connection try again later.",
      );
      reset(); // Close the connection if an error occurs
    };

    return { taskUuid, error: false };
  };

  return {
    connectSSE,
    isStreaming,
  };
}
