import { useQuery, useMutation } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import CustomizationOptions from "./CustomizationOptions.js";
import GeneratedSummary from "./GeneratedSummary.js";
import SelectType from "./SelectType.js";
import { DEFAULT_CLIENT_SUMMARY_PREFERENCES } from "../../../../../constants.js";
import {
  EDIT_PREFERENCES_MUTATION,
  GENERATE_CLIENT_SUMMARY,
} from "../../../../../graphql/mutations/ClientSummary.js";
import EXPORT_CLIENT_SUMMARY_EMAIL from "../../../../../graphql/mutations/SendClientSummaryEmail.js";
import CURRENT_USER_QUERY from "../../../../../graphql/queries/CurrentUser.js";
import { useStreamResult } from "../../../../../hooks";
import { useAutosaveContext } from "../../../../../hooks/use-autosave.js";
import { alert } from "../../../../common/Alert.js";
import ExportModal from "../../../../email/ExportModal.js";
import { mapStateToPreferences } from "../utils.js";

const SAVE_BUTTON_TEXT = "Save as Default";

export default function ClientSummary({ note }) {
  //form methods

  const methods = useFormContext();
  const { control, handleSubmit, getValues, setValue } = methods;
  const { setIsStreaming } = useAutosaveContext();

  const [isClientSummaryEditable, setIsClientSummaryEditable] =
    useState(false);

  //queries and mutations
  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const [editPreferences] = useMutation(EDIT_PREFERENCES_MUTATION);
  const [generateSummary, { error: summaryError }] = useMutation(
    GENERATE_CLIENT_SUMMARY,
  );
  const [exportClientSummaryEmail] = useMutation(
    EXPORT_CLIENT_SUMMARY_EMAIL,
  );

  //state
  const [frontmatter, setFrontmatter] = useState(""); // likely need to be removed in next refactor for useForm consistency.
  const [backmatter, setBackmatter] = useState(""); // likely need to be removed in next refactor for useForm consistency.
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [step, setStep] = useState(note?.clientSummary ? 3 : 1);
  const [clientSummaryOptions, setClientSummaryOptions] = useState(
    DEFAULT_CLIENT_SUMMARY_PREFERENCES,
  );

  //refs
  const generatedSummaryRef = useRef(null);
  const frontmatterRef = useRef(null);
  const backmatterRef = useRef(null);

  const { connectSSE, isStreaming } = useStreamResult();

  useEffect(() => {
    if (note?.clientSummary) {
      setIsClientSummaryEditable(true);
    }
  }, [userData, note]);

  // useEffect(() => {

  // not sure what this code was for
  // Commenting out for now but likely broken
  // if (userData?.user?.clientSummaryPreferences) {
  //   const prefs = userData.user.clientSummaryPreferences;
  //   const updatedOptions = { ...clientSummaryOptions };

  //   Object.keys(updatedOptions).forEach((key) => {
  //     if (prefs?.hasOwnProperty(key)) {
  //       updatedOptions[key] = {
  //         ...updatedOptions[key],
  //         include: prefs[key],
  //       };
  //     }
  //   });

  // setClientSummaryOptions(updatedOptions);
  // setFrontmatter(prefs?.frontmatter || "");
  // setBackmatter(prefs?.backmatter || "");
  // }
  // }, [userData, note]);

  const handleSave = () => {
    const preferencesData = mapStateToPreferences(
      clientSummaryOptions,
    );
    editPreferences({
      variables: {
        frontmatter: frontmatter,
        backmatter: backmatter,
        ...preferencesData,
      },
    }).catch((error) => {
      console.error("Error updating preferences", error);
    });
  };

  const handleGenerateSummary = async (isSimple) => {
    const options = Object.keys(clientSummaryOptions).filter(
      (key) => clientSummaryOptions[key].include,
    );
    const formValues = getValues();

    let response = await connectSSE(
      (message) => {
        const clientSummary = getValues("clientSummary");
        setValue("clientSummary", clientSummary + message);
      },
      () => {
        // use the client summary messages in state to set
        // the form value. Swap out from display only to an editable form field

        setIsClientSummaryEditable(true);
      },
      "Client Summary Generation",
    );

    if (response?.taskUuid === "" || response?.error) {
      alert(
        "error",
        "An error has occurred. Please try again later.",
      );

      setStep(1);
      return;
    }

    const taskUuid = response?.taskUuid;

    setIsClientSummaryEditable(false);
    setIsStreaming(true);
    setValue("clientSummary", "");
    mixpanel.track("Client Summary Generated", {
      noteUuid: note?.uuid,
      summaryType: isSimple ? "simple" : "custom",
    });
    generateSummary({
      variables: {
        noteUuid: note?.uuid,
        subjective: formValues?.subjective,
        objective: formValues?.objective,
        assessment: formValues?.assessment,
        plan: formValues?.plan,
        formOptions: !isSimple && options,
        summaryType: isSimple ? "simple" : "custom",
        streamingTaskUuid: taskUuid,
      },
    }).catch((error) => {
      console.error("Error generating client summary:", error);
      mixpanel.track("Client Summary Generation Error", {
        noteUuid: note?.uuid,
        summaryType: isSimple ? "simple" : "custom",
        error: error.message,
      });
      alert(
        "error",
        "An error has occurred. Please try again later.",
      );
      setStep(1);
    });
  };

  const onSend = async (data) => {
    try {
      await exportClientSummaryEmail({
        variables: {
          toEmail: data?.toEmail,
          clientSummary: getValues("clientSummary"),
        },
      });
      const successMessage =
        "Client summary sent to " + data?.toEmail + "!";
      alert("success", successMessage);
      setShouldShowModal(false);
    } catch (error) {
      console.error("Error sending client summary:", error);
    }
  };

  return (
    <>
      <ExportModal
        isClientSummaryMode={true}
        shouldShowModal={shouldShowModal}
        isClientSummaryModalOpen={shouldShowModal}
        hideModal={() => setShouldShowModal(false)}
        handleSubmit={handleSubmit}
        onSend={onSend}
        control={control}
        methods={methods}
        frontmatter={frontmatter}
        backmatter={backmatter}
        note={note}
      />
      {step === 1 && (
        <SelectType
          setStep={setStep}
          handleGenerateSummary={handleGenerateSummary}
        />
      )}

      {step === 2 && (
        <CustomizationOptions
          frontmatter={frontmatter}
          setFrontmatter={setFrontmatter}
          frontmatterRef={frontmatterRef}
          backmatter={backmatter}
          setBackmatter={setBackmatter}
          backmatterRef={backmatterRef}
          clientSummaryOptions={clientSummaryOptions}
          setClientSummaryOptions={setClientSummaryOptions}
          handleSave={handleSave}
          saveButtonText={SAVE_BUTTON_TEXT}
          handleGenerateSummary={handleGenerateSummary}
          setStep={setStep}
        />
      )}

      {step === 3 && (
        <GeneratedSummary
          generatedSummaryRef={generatedSummaryRef}
          handleExportClientSummary={() => {
            setShouldShowModal(true);
          }}
          setStep={setStep}
          isClientSummaryEditable={isClientSummaryEditable}
          control={control}
          isStreaming={isStreaming}
          noteUuid={note?.uuid}
          isAutoSoap={note?.isAutoSoap}
        />
      )}

      {summaryError && (
        <h4 className="mt-10 text-center">
          Error - Please try again later: {summaryError.message}
        </h4>
      )}
    </>
  );
}
