import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export function UploadingRecordingButton({
  percentCompleted,
  trackColor,
}) {
  return (
    <div className="flex-1 h-full w-full">
      <CircularProgressbarWithChildren
        value={percentCompleted}
        styles={{
          path: {
            // Path color
            stroke: "#818CF8",
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "round",
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: trackColor,
            strokeLinecap: "round",
          },
        }}
      >
        <div className="flex text-center my-6 rounded-full relative w-3/4 h-3/4 bg-gradient-to-br from-indigo-400 to-indigo-800 transition-all place-items-center place-content-center shadow-lg">
          <div className="text-white text-5xl text-center font-bold self-center">
            {percentCompleted}%
          </div>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
